@tailwind base;
@tailwind components;
@tailwind utilities;

.iframe {
  height: calc(100vh - theme("spacing.48"));
}

.PhoneInputInput {
  @apply p-3 border-0 ring-0 text-base rounded-lg focus:ring-0 focus:border-0 active:border-0;
}

.PhoneInputCountry {
  @apply pl-3;
}
